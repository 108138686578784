<template>
  <div>
    <section class="container p-0 mb-4 dds">
      <h1 class="h4 pl-3">Destaques da semana:</h1>
      <carousel
        :perPageCustom="[
          [425, 3],
          [768, 4],
          [1024, 6],
          [1440, 7],
        ]"
        :navigationEnabled="true"
        :paginationEnabled="false"
      >
        <slide v-for="(product, index) in productsList" :key="index">
          <div
            :data-test="`product-${formatName(product.descricao)}`"
            class="card"
            @click.prevent="
              goToProduct(
                product.categoria.descricao,
                product.descricao,
                product.id
              )
            "
          >
            <div
              class="card-img"
              v-if="product.imagem[0] && product.imagem[0].link"
              :style="`background-image: url(${ajustarImg(
                product.imagem[0].link
              )})`"
            ></div>
            <div
              class="card-img"
              v-else
              :style="`background-image: url(${product.imageThumbnail})`"
            ></div>
            <div class="card-content">
              <p class="text-truncate">{{ product.descricao }}</p>
              <h5 class="mb-2">
                <span class="price" v-if="!product.precoPromocional">
                  {{
                    ((product.preco / 100) * 95)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                </span>
                <span class="price" v-else>
                  <span class="normal-price">
                    <span class="dashed">
                      {{
                        ((product.preco / 100) * 95)
                          | currency("R$ ", 2, {
                            thousandsSeparator: ".",
                            decimalSeparator: ",",
                          })
                      }}
                    </span>

                    <span class="pct">
                      <img src="@/assets/img/svg/arrow-down.svg" alt="" />
                      <span>
                        {{
                          calculateDiscountPercentage(
                            product.preco,
                            product.precoPromocional
                          )
                        }}%
                      </span>
                    </span>
                  </span>
                  <span class="promo-price">
                    {{
                      ((product.precoPromocional / 100) * 95)
                        | currency("R$ ", 2, {
                          thousandsSeparator: ".",
                          decimalSeparator: ",",
                        })
                    }}
                  </span>
                </span>
                <span style="font-size: 14px; display: block">
                  À vista no PIX ou
                </span>
              </h5>

              <small>
                <span v-if="!product.precoPromocional">
                  {{
                    product.preco
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                </span>
                <span v-else>
                  {{
                    product.precoPromocional
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                </span>
                no cartão de crédito em até 3x sem juros
              </small>
            </div>
          </div>
        </slide>
      </carousel>
    </section>

    <section class="container p-0 mb-4 cpc">
      <h1 class="h4 pl-3 mb-4">Compre por Categoria:</h1>

      <div class="container">
        <carousel
          :perPageCustom="[
            [320, 3],
            [375, 4],
            [768, 8],
            [1024, 12],
          ]"
          :navigationEnabled="true"
          :paginationEnabled="false"
        >
          <slide class="slide">
            <div
              data-test="category-eletrica"
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'ELÉTRICA' },
                })
              "
            >
              <img
                src="@/assets/img/png/cat-eletrica.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Elétrica</p>
          </slide>

          <slide class="slide">
            <div
              data-test="category-iluminacao"
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'ILUMINAÇÃO' },
                })
              "
            >
              <img
                src="@/assets/img/png/cat-iluminacao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Iluminação</p>
          </slide>

          <slide class="slide">
            <div
              data-test="category-motor"
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'MOTOR' },
                })
              "
            >
              <img src="@/assets/img/png/cat-motores.png" style="width: 100%" />
            </div>
            <p class="mb-0">Motor</p>
          </slide>

          <slide class="slide">
            <div
              data-test="category-pneus"
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'PNEUS' }, //PNEUS
                })
              "
            >
              <img
                src="@/assets/img/png/cat-pneus-e-camaras.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Pneus e Câmaras</p>
          </slide>

          <slide class="slide">
            <div
              data-test="category-retrovisores"
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'RETROVISORES' },
                })
              "
            >
              <img
                src="@/assets/img/png/cat-retrovisores.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Retrovisor</p>
          </slide>

          <slide class="slide">
            <div
              data-test="category-suspensao"
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'SUSPENSÃO' },
                })
              "
            >
              <img
                src="@/assets/img/png/cat-suspensao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Suspensão</p>
          </slide>

          <slide class="slide">
            <div
              data-test="category-transmissao"
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'TRANSMISSÃO' },
                })
              "
            >
              <img
                src="@/assets/img/png/cat-transmissao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Transmissão</p>
          </slide>
        </carousel>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "HomeView",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      banner: [],
    };
  },
  computed: {
    ...mapGetters({
      productsList: "productsList",
      productsListLoading: "productsListLoading",
      getUser: "getUser",
    }),
  },
  methods: {
    ...mapActions({
      fetchProductsList: "fetchProductsList",
      fetchBanner: "fetchBanner",
    }),
    ajustarImg(img) {
      return img.replace("http:", "https:");
    },
    goToRoute(item) {
      if (item.link.includes("https://")) {
        window.location.href = item.link;
      } else {
        if (item.link.includes("product-list")) {
          this.$router.push({
            name: "product-list",
            params: { categoria: item.link.replace("#/product-list/", "") },
          });
        } else {
          this.$router.push({
            name: "product",
            params: { id: item.link.replace("#/product/", "") },
          });
        }
      }
    },

    goToProduct(cat, nam, id) {
      localStorage.setItem("gtpId", id);

      const alt = (x) => {
        return x
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9\s]/g, "")
          .replace(/\s+/g, "-")
          .toLowerCase();
      };

      const newCategory = alt(cat);
      const newName = alt(nam);

      this.$router.push({
        name: "product",
        params: { category: newCategory, name: newName, id: id },
      });
    },

    handleSlideClick() {
      //console.log(dataset.index, dataset.name)
    },

    calculateDiscountPercentage(originalPrice, promotionalPrice) {
      if (!promotionalPrice || promotionalPrice === originalPrice) {
        return 0;
      }
      const discount = (originalPrice - promotionalPrice) / originalPrice;
      return (discount * 100).toFixed(0);
    },

    formatName(name) {
      return name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase();
    },
  },

  async beforeCreate() {
    var homeAfterCheckout = localStorage.getItem("ifFac");
    if (homeAfterCheckout === "true") {
      localStorage.setItem("ifFac", "false");
      location.reload();
      return;
    }
  },

  async mounted() {
    await this.fetchProductsList({
      limit: 50,
      situacao: "Ativo",
      group: "Motopeças",
    });
  },
};
</script>

<style lang="scss" scoped>
// #featured-banner {
// 	&.card {
// 		border: none;
// 		border-radius: 4px;
// 		box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.16);

// 		img {
// 			border-radius: 4px;
// 		}
// 	}

// 	img {
// 		max-width: 100%;
// 	}
// }

::v-deep .VueCarousel {
  .VueCarousel-navigation,
  .VueCarousel-pagination {
    display: none;
  }
}

.carouselImage {
  border: none;
  border-radius: 4px;
  width: 100%;
  // object-fit: contain;
  //box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.16);
  max-width: 100%;

  @media (max-width: 550px) {
  }
}

.category-img {
  margin: auto;
  margin-top: 0;
}

.cat {
  margin-bottom: 25px;

  img {
    width: 100%;
  }
}

::v-deep .cpc {
  .slide {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    img {
      max-width: 70px;
    }

    p {
      text-align: center;
      font-size: 14px;
    }
  }

  .VueCarousel-navigation {
    display: unset;

    .VueCarousel-navigation-button {
      color: red;
    }

    .VueCarousel-navigation--disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}

::v-deep .dds {
  .VueCarousel-slide {
    margin: 15px 0;
    display: flex;
    // align-items: center;
    justify-content: center;
  }

  .VueCarousel-navigation {
    display: unset;

    .VueCarousel-navigation-button {
      color: red;
    }

    .VueCarousel-navigation--disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}

::v-deep .VueCarousel-navigation {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.card {
  border: none;
  padding: 0;
  border-radius: 5px;
  width: 90%;
  box-shadow: 0 3px 6px 4px rgba(0, 0, 0, 0.1) !important;
  align-items: flex-start;

  &-img {
    width: 135px;
    height: 135px;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    position: relative;
    border-radius: 5px;
    background: no-repeat center center / cover;

    @media (max-width: 320px) {
      width: 100%;
      min-height: 150px;
    }

    @media (max-width: 425px) {
      width: 100%;
      min-height: 180px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 135px;
    }
  }

  &-content {
    font-size: 0.75rem;
    padding: 0.5rem;
    white-space: normal;

    p {
      margin-bottom: 0.25rem;
    }

    h5 {
      margin: 0;
    }
  }
}

.text-truncate {
  max-width: 115px;
}

.price {
  width: 100%;
  justify-content: space-between;

  .normal-price {
    width: 100%;
    justify-content: space-between;
    font-size: 14px;

    .pct {
      padding: 3px 10px;
      font-size: 12px;
    }
  }
  .promo-price {
    .por {
      font-size: 12px;
    }
  }
}
</style>
